<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="党组织" span="6">
          <CustomFuzzySearch
            ref="partyOrganizationFuzzySearch"
            search-field="name"
            show-field="name"
            :props="partyOrganizationFuzzySearchProps"
            :search-value="listQuery['param']['organizationId']"
            @searchChange="listQuery['param']['organizationId']=$event.id"
            @searchUpdate="listQuery['param']['organizationId']=''"
            @searchData="partyOrganizationFuzzySearchData"
          />
        </vxe-form-item>
        <vxe-form-item title="姓名" span="6">
          <vxe-input v-model="listQuery.param.name" placeholder="请输入姓名" clearable />
        </vxe-form-item>
        <vxe-form-item title="联系方式" span="6">
          <vxe-input v-model="listQuery.param.phone" placeholder="请输入联系方式" clearable />
        </vxe-form-item>
        <vxe-form-item title="状态" span="6">
          <vxe-select v-model="listQuery.param.reviewStatus" placeholder="请选择状态" clearable>
            <vxe-option
              v-for="(item, index) in reviewStatusArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'PartyPartyReleaseRecordButton'" size="small" type="text" @click="goTaskReleaseRecord(row.userId)">发布任务记录</el-button>
        <el-button v-permission="'PartyPartyParticipationRecordButton'" size="small" type="text" @click="goTaskParticipationRecord(row.userId)">参与任务记录</el-button>
      </template>
    </CustomList>
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import CustomFuzzySearch from '@/components/CustomFuzzySearch/index'
export default {
  name: 'Party',
  components: { CustomFuzzySearch, CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      reviewStatusArray: ['待审核', '已通过', '已拒绝'],
      genderArray: ['未知', '男', '女'],
      listQuery: {
        param: {
          organizationId: ''
        }
      },
      tableColumns: [
        {
          prop: 'partyOrganizationName',
          title: '党组织名称',
          minWidth: 100
        },
        {
          prop: 'name',
          title: '姓名',
          minWidth: 100
        },
        {
          title: '性别',
          formatter: (row, column) => {
            if (row.gender in this.genderArray) {
              return this.genderArray[row.gender]
            } else {
              return ''
            }
          },
          minWidth: 100
        },
        {
          prop: 'age',
          title: '年龄',
          minWidth: 100
        },
        {
          prop: 'phone',
          title: '联系方式',
          minWidth: 150
        },
        {
          prop: 'partyAge',
          title: '党龄',
          minWidth: 100
        },
        {
          prop: 'creditsBalance',
          title: '积分余额',
          minWidth: 100
        },
        {
          prop: 'currentYearCredits',
          title: '当年总积分',
          minWidth: 100
        },
        {
          prop: 'credits',
          title: '总积分',
          minWidth: 100
        },
        {
          title: '状态',
          formatter: (row, column) => {
            if (row.reviewStatus in this.reviewStatusArray) {
              let text = this.reviewStatusArray[row.reviewStatus]
              if (row.reviewStatus === 2 && row.reason) {
                text += `：${row.reason}`
              }
              return text
            } else {
              return ''
            }
          },
          minWidth: 150
        },
        {
          prop: 'entryDate',
          title: '入党时间',
          minWidth: 160
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 180
        }
      ],
      partyOrganizationFuzzySearchProps: [{ prop: 'name', label: '名称' }]
    }
  },
  methods: {
    ...mapActions(['partyOrganizationPublicPageList', 'partyPageList']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    partyOrganizationFuzzySearchData(param, cb) {
      this.partyOrganizationPublicPageList(param).then(res => {
        cb(res)
      }).catch(() => {})
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.partyPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    goTaskReleaseRecord(initiatorUserId) {
      this.$router.push({ name: 'TaskReleaseRecord', params: { initiatorUserId }, query: { belongs: 1 }})
    },
    goTaskParticipationRecord(claimantUserId) {
      this.$router.push({ name: 'TaskParticipationRecord', params: { claimantUserId }})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
